<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 24px" class="headtitle">
            บันทึกข้อมูลรายงานผลการปฏิบัติงานชุมชนยั่งยืนผ่านระบบออนไลน์
          </p>
          <p style="font-size: 24px" class="headtitle">(สำหรับหัวหน้าสถานี)</p>
          <!-- <p style="font-size: 24px" class="headtitle">
            เพื่อแก้ไขปัญหายาเสพติด แบบครบวงจร ตามยุทธศาสตร์ชาติ
          </p> -->
          <!-- <p style="font-size: 24px" class="headtitle">
            ตำรวจภูธรภาค {{ checklogin.station }}
          </p> -->
          <br />

          <v-row class="text-left">
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="สถานี"
                outlined
                v-model="data.station"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จังหวัด"
                outlined
                v-model="data.province"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-menu
                ref="menufrom"
                v-model="menufrom"
                :close-on-content-click="false"
                :return-value.sync="data.datefrom"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.datefrom"
                    label="วันที่บันทึกข้อมูลล่าสุด"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="true"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="data.datefrom" no-title scrollable>
                  <v-btn text color="primary" @click="menufrom = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menufrom.save(data.datefrom)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ผู้ที่บันทึกข้อมูลล่าสุด"
                outlined
                v-model="data.staffName"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12">
              <span><b>1) ข้อมูลประชากรและครัวเรือน</b></span>
            </v-col>
          </v-row>

          <v-row class="text-left">
            <v-col cols="12">
              <span>1. จำนวนประชากรอายุ(12-65 ปี)</span>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_1_m"
                label="จำนวนเพศชาย"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                type="number"
                @input="summary1_1()"
              >
              </v-text-field>
            </v-col>
            <!-- @keypress="summary1_1()" -->
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_1_f"
                label="จำนวนเพศหญิง"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                type="number"
                @input="summary1_1()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_1_s"
                label="รวม"
                disabled
                outlined
                dense
                
                suffix="คน"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>2. จำนวนประชากรในการ X-Ray ตามข้อตกลงชุมชน</span>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_2_m"
                label="จำนวนเพศชาย"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                type="number"
                @input="summary1_2()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_2_f"
                label="จำนวนเพศหญิง"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                type="number"
                @input="summary1_2()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_2_s"
                label="รวม"
                disabled
                outlined
                dense
                
                suffix="คน"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>3. จำนวนตรวจหาสารเสพติด X-Ray</span>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_3_m"
                label="จำนวนเพศชาย"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                type="number"
                @input="summary1_3()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_3_f"
                label="จำนวนเพศหญิง"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                type="number"
                @input="summary1_3()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_3_s"
                label="รวม"
                disabled
                outlined
                dense
                
                suffix="คน"
                type="number"
                
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>4. จำนวนพบผู้เสพยาเสพติด</span>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_4_m"
                label="จำนวนเพศชาย"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                type="number"
                @input="summary1_4()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_4_f"
                label="จำนวนเพศหญิง"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                type="number"
                @input="summary1_4()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_4_s"
                label="รวม"
                disabled
                outlined
                dense
                
                suffix="คน"
                type="number"
                
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>5. จำนวนครัวเรือนจริงในชุมชน</span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_5"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="ครัวเรือน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>6. ครัวเรือนที่ทำข้อตกลง MOU</span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_6"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="ครัวเรือน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>7. จำนวนคุ้ม</span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_7"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คุ้ม"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 8. จำนวนสมาชิกคุ้มรวมทุกคุ้ม </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_8"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12">
              <span
                ><b
                  >2) สรุปผลการดำเนินงานตามเกณฑ์การประเมินชุมชนยั่งยืน</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.1)
                  ร้อยละจำนวนผู้เสพยาในชุมชนลดลงจากฐานข้อมูลเริ่มโครงการหลังส่งมอบพื้นที่</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.1.1. จำนวนผู้เสพที่ค้นพบ ก่อนส่งมอบพื้นที่ </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_1"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.1.2. จำนวนผู้เข้ารับการบำบัด CBTx </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_2"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_1_5()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.1.3. จำนวนผู้เลิกเสพจากกระบวนการบำบัด CBTx (ตามเอกสารรับรอง
                สธ.)
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_3"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_1_5()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.1.4. จำนวนผู้เข้ารับการบำบัดโดยวิธีอื่น (ไม่ใช่ CBTx)</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_4"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.1.5. ร้อยละจำนวนผู้เสพลดลงหลังส่งมอบพื้นที่ [(3) / (2) x
                100]</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_5"
                label="จำนวน"
                type="number"
                outlined
                dense
                
                suffix="%"
                
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.2)
                  ร้อยละจำนวนผู้ค้าในชุมชนลดลงจากฐานข้อมูลเริ่มโครงการหลังส่งมอบพื้นที่</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.2.1. จำนวนผู้ค้าก่อนส่งมอบพื้นที่
                (แจ้งเบาะแส+สืบสภาพชุมชน)</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_2_1"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_2_3()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.2.2. จำนวนผู้ค้าหลังส่งมอบพื้นที่ (ตามบันทึกจับกุม+ หลบหนี +
                หมายจับ )</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_2_2"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_2_3()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.2.3. ร้อยละจำนวนผู้ค้าลดลงหลังส่งมอบพื้นที่ [(2) / (1) x
                100]</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_2_3"
                label="จำนวน"
                type="number"
                outlined
                dense
                
                suffix="%"
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.3)
                  ร้อยละจำนวนครัวเรือนมีสมาชิกยุ่งเกี่ยวกับยาเสพติดลดลงจากฐานข้อมูลเริ่มโครงการหลังส่งมอบพื้นที่</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.3.1. จำนวนครัวเรือนมีสมาชิกยุ่งเกี่ยวกับยาเสพติด ( x-ray +
                เบาะแส+ สืบสภาพชุมชน)</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_3_1"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="ครัวเรือน"
                @change="q_2_3_3()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.3.2.
                จำนวนครัวเรือนมีสมาชิกยุ่งเกี่ยวกับยาเสพติดหลังส่งมอบพื้นที่
                (ตามเอกสารรับรอง สธ. + บันทึกการจับกุม + หลบหนี + (หมายจับ)
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_3_2"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="ครัวเรือน"
                @change="q_2_3_3()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.3.3. ร้อยละจำนวนครัวเรือนลดลงหลังส่งมอบพื้นที่ [(2) / (1) x
                100]</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_3_3"
                label="จำนวน"
                type="number"
                outlined
                dense
                
                suffix="%"
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.4) ร้อยละความสำเร็จในการแก้ปัญหาจากระบบการแจ้งเบาะแส</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.4.1. จำนวนการแจ้งเบาะแส</span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_4_1"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="เรื่อง"
                @change="q_2_4_3()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.3.2. จำนวนเบาะแสที่ดำเนินการแล้ว </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_4_2"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="เรื่อง"
                @change="q_2_4_3()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.4.3. ร้อยละความสำเร็จในการแก้ปัญหาจากระบบแจ้งเบาะแส [(2) / (1)
                x 100]</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_4_3"
                label="จำนวน"
                type="number"
                outlined
                dense
                
                suffix="%"
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.5)
                  ร้อยละจำนวนผู้เสพยาที่ร่วมโครงการในชุมชนได้รับการดูแลคุณภาพชีวิตที่ดีขึ้นหลังเข้าร่วมโครงการ</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.1. จำนวนผู้เข้ารับการบำบัด CBTX </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_1"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_5_7()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.2. จำนวนผู้ที่เข้ารับการศึกษาต่อ </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_2"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_5_7()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.3. จำนวนผู้ได้รับการจ้างงาน </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_3"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_5_7()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.4. จำนวนผู้ได้รับการฝึกอาชีพ </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_4"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_5_7()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.5. จำนวนผู้ได้รับจากการพัฒนาตนเอง </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_5"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_5_7()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.5.6. จำนวนผู้รับได้รับการช่วยเหลือเงิน หรือ
                ปัจจัยในการดำรงชีวิต
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_6"
                label="จำนวน"
                type="number"
                outlined
                dense
                :rules="rules.required"
                suffix="คน"
                @change="q_2_5_7()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.5.7. รวมจำนวนผู้ที่ได้รับการส่งเสริม (2)+(3)+(4)+(5)+(6)
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_7"
                label="จำนวน"
                type="number"
                outlined
                dense
                
                suffix="คน"
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.5.8.
                ร้อยละจำนวนผู้เสพยาที่ร่วมโครงการในชุมชนได้รับการดูแลคุณภาพชีวิตที่ดีขึ้น
                [(7) / (1) x 100]
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_8"
                label="จำนวน"
                type="number"
                outlined
                dense
                suffix="%"
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12">
              <span
                ><b
                  >3.)รายงานการวิเคราะห์ผลการดำเนินงานโครงการชุมชนยั่งยืน (SWOT
                  Analysis)</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left mt-4" no-gutters>
            <v-col cols="12" class="ml-4">
              <span><b>3.1 จุดแข็ง</b></span>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-textarea
                v-model="data.q_3_1"
                label=""
                outlined
                dense
                :rules="rules.required"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span><b>3.2. จุดอ่อน</b></span>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-textarea
                v-model="data.q_3_2"
                label=""
                outlined
                dense
                :rules="rules.required"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span><b>3.3. โอกาส </b></span>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-textarea
                v-model="data.q_3_3"
                label=""
                outlined
                dense
                :rules="rules.required"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span><b>3.4. อุปสรรค</b></span>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-textarea
                v-model="data.q_3_4"
                label=""
                outlined
                dense
                :rules="rules.required"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <span
                ><b
                  >อัพโหลดรายงานผลการดำเนินงานโครงการดำเนินงานชุมชนยั่งยืน</b
                ></span
              >
              <br />
              <span style="color: red"
                >สามารถอัพโหลดเฉพาะไฟล์ .pdfเท่านั้น</span
              >
            </v-col>
            <v-col cols="12" md="4">
              <input
                type="file"
                accept="application/pdf"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
            /></v-col>
            <v-col cols="12" md="4">
              <v-btn @click="submitFile()">อัพโหลด</v-btn>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-btn color="primary" @click="submit()"> บันทึก</v-btn>
          </v-row>
          <!-- ///////////////////////////////////////////// -->
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      checkValidate: true,
      lazy: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [(v) => v.length <= 13 || "Max 13 characters"],
        maxlength10: [(v) => v.length <= 10 || "Max 10 characters"],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      data: {
        station: "",
        province: "",
        datefrom: "",
        userId: "",
        staffName: "",
        q_1_1_m: null,
        q_1_1_f: null,
        q_1_2_m: null,
        q_1_2_f: null,
        q_1_3_m: null,
        q_1_3_f: null,
        q_1_4_m: null,
        q_1_4_f: null,
        q_1_1_s: null,
        q_1_2_s: null,
        q_1_3_s: null,
        q_1_4_s: null,
        q_1_5: null,
        q_1_6: null,
        q_1_7: null,
        q_1_8: null,
        q_2_1_1: null,
        q_2_1_2: null,
        q_2_1_3: null,
        q_2_1_4: null,
        q_2_1_5: null,
        q_2_2_1: null,
        q_2_2_2: null,
        q_2_2_3: null,
        q_2_3_1: null,
        q_2_3_2: null,
        q_2_3_3: null,
        q_2_4_1: null,
        q_2_4_2: null,
        q_2_4_3: null,
        q_2_5_1: null,
        q_2_5_2: null,
        q_2_5_3: null,
        q_2_5_4: null,
        q_2_5_5: null,
        q_2_5_6: null,
        q_2_5_7: null,
        q_2_5_8: null,
        q_3_1: null,
        q_3_2: null,
        q_3_3: null,
        q_3_4: null,
        file: "",
      },
      menufrom: false,
      file: "",
      type: "uploadfile",
    };
  },
  // watch:{
  //   q_2_1_5(){
  //     this.data.q_2_1_5 = (this.data.q_2_1_3 /this.data.q_2_1_2)*100
  //   }
  //   ร้อยละจำนวนผู้เสพลดลงหลังส่งมอบพื้นที่ [(3) / (2) x 100]
  // },
  // computed: {
  //   q_2_1_5: function () {
  //     return this.data.q_2_1_5 = (this.data.q_2_1_3 /this.data.q_2_1_2)*100
  //   }
  // },
  async created() {
    this.data.file = "";
    var checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
    // console.log(this.checklogin);
    this.data.userId = checklogin.id;
    this.data.station = checklogin.station;
    this.data.province = checklogin.province;
    const response = await this.axios.post(
      `${process.env.VUE_APP_API}/researchForms/findAll?station=` +
        this.data.station,
      {}
    );
    console.log("getstation11111", response.data.data[0]);
    if (response.data.data.length > 0) {
      const responseUser = await this.axios.get(
        `${process.env.VUE_APP_API}/user/getOne/` + response.data.data[0].userId
      );
      console.log("responseUser", responseUser);
      if (responseUser.data.response_status == "SUCCESS") {
        console.log(responseUser);
        this.data = response.data.data[0];
        this.data.datefrom = this.convertDate(response.data.data[0].updatedAt);
        this.data.staffName = responseUser.data.data.name;
        this.data.userId = checklogin.id;
        console.log(this.data.staffName);
      } else {
        this.data.staffName = "-";
      }
    }
  },
  methods: {
    convertDate(date) {
      return moment(String(date)).format("MM/DD/YYYY HH:MM");
    },
    q_2_1_5() {
      if (this.data.q_2_1_3 == 0 && this.data.q_2_1_2 == 0) {
        this.data.q_2_1_5 = 100;
      } else if (this.data.q_2_1_3 && this.data.q_2_1_2) {
        this.data.q_2_1_5 = (this.data.q_2_1_3 / this.data.q_2_1_2) * 100;
      }
    },
    q_2_2_3() {
      if (this.data.q_2_2_2 == 0 && this.data.q_2_2_1 == 0) {
        this.data.q_2_2_3 = 100;
      } else if (this.data.q_2_2_2 && this.data.q_2_2_1) {
        this.data.q_2_2_3 = (this.data.q_2_2_2 / this.data.q_2_2_1) * 100;
      }
    },
    q_2_3_3() {
      if (this.data.q_2_3_2 == 0 && this.data.q_2_3_1 == 0) {
        this.data.q_2_3_3 = 100;
      } else if (this.data.q_2_3_2 && this.data.q_2_3_1) {
        this.data.q_2_3_3 = (this.data.q_2_3_2 / this.data.q_2_3_1) * 100;
      }
    },
    q_2_4_3() {
      if (this.data.q_2_4_2 == 0 && this.data.q_2_4_1 == 0) {
        this.data.q_2_4_3 = 100;
      } else if (this.data.q_2_4_2 && this.data.q_2_4_1) {
        this.data.q_2_4_3 = (this.data.q_2_4_2 / this.data.q_2_4_1) * 100;
      }
    },
    q_2_5_7() {
      if (
        this.data.q_2_5_1 == 0 &&
        this.data.q_2_5_2 == 0 &&
        this.data.q_2_5_3 == 0 &&
        this.data.q_2_5_4 == 0 &&
        this.data.q_2_5_5 == 0 &&
        this.data.q_2_5_6 == 0
      ) {
        this.data.q_2_5_7 = "0";
        this.data.q_2_5_8 = 100;
      } else if (
        this.data.q_2_5_2 &&
        this.data.q_2_5_3 &&
        this.data.q_2_5_4 &&
        this.data.q_2_5_5 &&
        this.data.q_2_5_6
      ) {
        this.data.q_2_5_7 =
          this.data.q_2_5_2 * 1 +
          this.data.q_2_5_3 * 1 +
          this.data.q_2_5_4 * 1 +
          this.data.q_2_5_5 * 1 +
          this.data.q_2_5_6 * 1;
        this.data.q_2_5_8 = ((this.data.q_2_5_7 / this.data.q_2_5_1) * 100).toFixed(2);
      }
    },
    summary1_1() {
      if (this.data.q_1_1_m == 0 && this.data.q_1_1_f == 0) {
        this.data.q_1_1_s = "0";
      } else {
        this.data.q_1_1_s = this.data.q_1_1_m * 1 + this.data.q_1_1_f * 1;
      }
    },
    summary1_2() {
      if (this.data.q_1_2_m == 0 && this.data.q_1_2_f == 0) {
        this.data.q_1_2_s = "0";
      } else {
        this.data.q_1_2_s = this.data.q_1_2_m * 1 + this.data.q_1_2_f * 1;
      }
    },
    summary1_3() {
      if (this.data.q_1_3_m == 0 && this.data.q_1_3_f == 0) {
        this.data.q_1_3_s = "0";
      } else {
        this.data.q_1_3_s = this.data.q_1_3_m * 1 + this.data.q_1_3_f * 1;
      }
    },
    summary1_4() {
      if (this.data.q_1_4_m == 0 && this.data.q_1_4_f == 0) {
        this.data.q_1_4_s = "0";
      } else {
        this.data.q_1_4_s = this.data.q_1_4_m * 1 + this.data.q_1_4_f * 1;
      }
    },
    async handleFileUpload() {
      this.data.file = this.$refs.file.files[0];
      console.log("file", this.data.file);
    },
    async submitFile() {
      this.loading = true;
      let formData = new FormData();
      formData.append("files", this.data.file);
      formData.append("type", this.type);
      formData.append("userId", this.data.userId);
      console.log("formData", formData);
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer` + " " + this.checkuser.token,
        },
      };
      console.log("auth", auth);
      this.uploaded = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/files/formData`,
        formData,
        auth
      );
      // .then(function () {
      console.log("SUCCESS!!");
      console.log("response", response);
      this.uploaded = false;
      if (response.data.response_status == "SUCCESS") {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "อัพโหลดสำเร็จ",
        });
        this.data.file = response.data.data.path;
      } else {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "อัพโหลดไฟล์ไม่สำเร็จ",
        });
      }

      // })
      // .catch(function () {
      //   console.log("FAILURE!!");
      // });
    },
    async submit() {
      console.log("this.data.file", this.data.file);
      if (this.data.file == null || !this.data.file || this.data.file == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณาอัพโหลดไฟล์",
        });
      }
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        // console.log("เข้าเฉย");

        console.log("data", this.data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/researchForms/create`,
          this.data
          // this.data
        );
        console.log("response", response);
        if (response.data.response_status === "SUCCESS") {
          console.log("response", response);
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          this.loading = false;
          location.reload();
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "บันทึกข้อมูลไม่สำเร็จ",
          });
        }
        console.log("ยิงแล้ว", response);
      }
    },
  },
};
</script>